<template>
    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>Filters</h5>
        </b-card-header>
        <b-card-body>
            <b-row>
                <b-col cols="12" md="4" class="mb-md-0 mb-2">
                    <label>Name</label>
                    <b-form-input id="name" v-model="form.name.val" class="d-inline-block mr-1" />
                </b-col>
                <b-col cols="12" md="4" class="mb-md-0 mb-2">
                    <label>Created by</label>
                    <b-form-input id="name" v-model="form.created_by.val" class="d-inline-block mr-1" />
                </b-col>
                <b-col cols="12" md="4" class="mb-md-0 mb-2">
                    <label>Type</label>
                    <v-select v-model="form.type.val" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="types" :clearable="true" input-id="genre" />
                </b-col>
                <b-col cols="12" md="4" class="mb-md-0 mb-2">
                    <label>Active status</label>
                    <v-select v-model="form.is_active.val" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="activeStatus" label="name" :clearable="true" input-id="artist"  :reduce="(val) => val.val" />
                </b-col>
                <b-col cols="12" md="4" class="mb-md-0 mb-2">
                    <label>Has discount</label>
                    <v-select v-model="form.has_discount.val" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="discounts" label="name" :clearable="true" input-id="artist" />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="4" class="mb-md-0 mb-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="button" variant="danger" class="mt-1" @click="filterPlayLists"> Filter </b-button>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";
import { ref } from "@vue/composition-api";
import usePlayListList from "@/views/apps/play-list/usePlayListList";
import { avatarText } from "@core/utils/filter";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BFormInput,
        BButton,
        vSelect,
    },
    directives: {
        Ripple,
    },
    computed: {
        ...mapGetters("genre", ["getTypes"]),
        ...mapGetters("artist", ["getArtists"]),
    },
    props: {},
    setup() {
        const isLoading = ref();
        const {
            fetchPlaylist,
            tableColumns,
            perPage,
            pagination,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            musics,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
        } = usePlayListList();

        const blankForm = {
            name: {
                type: "like",
                val: null,
            },
            type: {
                type: "like",
                val: null,
            },
            has_discount: {
                type: "like",
                val: null,
            },
            is_active: {
                type: "like",
                val: null,
            },
            created_by: {
                type: "like",
                val: null,
            },
        };
        const form = ref(blankForm);
        const types = ref(["gift", "badge", "sticker_pack"]);
        const discounts = ref(["true", "false"]);
        const activeStatus = ref([
            { name: "active", val: true },
            { name: "not active", val: false },
        ]);
        return {
            form,
            isLoading,
            // Sidebar
            fetchPlaylist,
            pagination,
            tableColumns,
            perPage,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            musics,
            // Filter
            avatarText,
            types,
            discounts,
            activeStatus,
            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
        };
    },
    methods: {
        ...mapActions("genre", ["loadGenres"]),
        ...mapActions("artist", ["loadArtists"]),
        filterPlayLists() {
            this.$emit("filter", { page: 1, itemsPerPage: this.perPage }, this.form);
        },
    },
    created() {
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
